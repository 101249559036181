<script setup>
/******************************************************************************
 * PageComponent: ContactDataSubmissionCustomer
 *****************************************************************************/
import ContactDataSubmissionChannel from "./ContactDataSubmissionChannel.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import FormNavRow from "Components/Form/FormNavRow.vue";
import FormButton from "Components/Form/FormButton.vue";
import FormGlobalErrorMessages from "Components/Form/FormGlobalErrorMessages.vue";
import { useReadingStore } from "Stores/reading";
import { useAppStore } from "Stores/app";
import { CONTACT_CHANNELS } from "@/config";

const data = useReadingStore();
const appStore = useAppStore();
const props = defineProps({
    customerId: {
        type: String,
        required: true,
    },
    clientId: {
        type: Number,
        required: true,
    },
});
const customer = data.getCustomerData(props.customerId);
const onSubmit = () => {
    data.submitContactData(
        props.customerId,
        props.clientId,
        customer.customerNo
    );
};
const availableChannels = () =>
    CONTACT_CHANNELS.filter(
        (channel) => customer.contactSubmission[channel.what]
    );
const showSubmit = () =>
    CONTACT_CHANNELS.length !== customer.contactDone.length;
</script>

<template>
    <li class="customer">
        <header>
            <svg-icon name="customer" :size="0" />
            <span class="customer-name">{{
                data.getCustomerString(customerId)
            }}</span>
            <span class="customer-no"
                >Kundennummer: <cite>{{ customer.customerNo }}</cite></span
            >
        </header>
        <form @submit.prevent="onSubmit">
            <div class="form-fields">
                <contact-data-submission-channel
                    v-for="channel in availableChannels()"
                    :key="channel.what"
                    :what="channel.what"
                    :label="channel.label"
                    :icon="channel.icon"
                    :length="channel.length"
                    :client-id="clientId"
                    :customer-id="customerId"
                    :input-mode="channel.inputmode"
                />
            </div>
            <form-global-error-messages
                v-if="
                    data.getCustomerData(props.customerId).contactError.length >
                    1
                "
                :error-text="
                    data.getCustomerData(props.customerId).contactError
                "
                :top="0.5"
            />
            <form-nav-row v-if="showSubmit()" :top="0.5">
                <template #forward>
                    <form-button
                        text="Daten versenden"
                        icon-position="right"
                        icon-name="send"
                        importance="default"
                        :disabled="appStore.requesting"
                    />
                </template>
            </form-nav-row>
        </form>
    </li>
</template>

<style lang="scss" scoped>
.customer {
    padding: #{get($sizes, "base") * 0.5};

    background-color: get($client, "contactDataCustomerBg");
    border-radius: get($sizes, "radius");

    @include respond-to("medium") {
        padding: #{get($sizes, "base") * 1};
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: #{get($sizes, "base") * 0.5};
    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        margin-bottom: #{get($sizes, "base") * 1};
    }
}

.customer-no cite {
    color: palette("brand1", "color");

    font-style: normal;
}

.form-fields {
    display: flex;
    flex-direction: column;

    gap: #{get($sizes, "base") * 0.5};
}
</style>
